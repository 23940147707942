import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_BOOK_MOVERS_LONG_DISTANCE_EMAIL',
  request: (id) => request.post(`/mover-bookings/${id}/long-distance-quote-followups`,{}),
};

export const {
  action,
  reducer,
  usePending: useLongDistanceEmailPending,
} = generate.redux(creator);
