import { generate, request } from '@moved/services';

const creator = {
  namespace: 'USER_RESEND_INVITATION_EMAIL',
  request: (data={}) => request.post(`/residents/tenant-events/send-invite-for-email`, data),
};

export const {
  action,
  reducer,
  usePending: useResendInvitationEmailPending,
} = generate.redux(creator);

export const initialState = {};
