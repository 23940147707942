import React from 'react';

import { format, useModal } from '@moved/services';
import { Icon } from '@moved/ui';

import { EditAddressModal } from '../modals/EditAddressModal';
import { EditDateModal } from '../modals/EditDateModal';

import mapIcon from './assets/map-icon.png';
import CSS from './styles/MoveData.module.scss';

export const MoveData = ({ move, className }) => {
  const modal = useModal();

  const originAddress = (
    move?.origin_address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-out')?.lease?.address ?? // prepopulated from the lease data
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.tenant?.prior_address // prepopulated from the tenant data
  );
  const destinationAddress = (
    move?.destination_address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.lease?.address // prepopulated from the lease data
  );
  const moveDate = move?.move_date ?? move?.move_steps?.find(({date}) => date != null)?.date;

  const editOrigin = () => modal.open(<EditAddressModal name='origin' initial={originAddress} move={move} />);
  const editDestination = () => modal.open(<EditAddressModal name='destination' initial={destinationAddress} move={move} />);
  const editDate = () => modal.open(<EditDateModal initial={moveDate} move={move} />);

  return (
    <div className={CSS.container}>

      <div className={CSS.dataBlock}>
        { originAddress != null && (
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${originAddress?.google_place_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
        )}
        <div className='stackVertical gap-4 flex-auto'>
          <label className='labelXS contentSecondary'>Origin</label>
          <div className='labelM contentPrimary'>
            { originAddress != null ? format.address(originAddress) : '—' }
          </div>
        </div>
        <div className={CSS.editButton} onClick={editOrigin}>
          <Icon symbol='Edit' library='general' size='20px' />
        </div>
      </div>

      { destinationAddress == null && (<div className={CSS.divider} />)}

      <div className={CSS.dataBlock}>
        { destinationAddress != null && (
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${destinationAddress?.google_place_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
        )}
        <div className='stackVertical gap-4 flex-auto'>
          <label className='labelXS contentSecondary'>Destination</label>
          <div className='labelM contentPrimary'>
            { destinationAddress != null ? format.address(destinationAddress) : '—' }
          </div>
        </div>
        <div className={CSS.editButton} onClick={editDestination}>
          <Icon symbol='Edit' library='general' size='20px' />
        </div>
      </div>

      <div className={CSS.divider} />

      <div className={CSS.dataBlock}>
        <div className='stackVertical gap-4 flex-auto'>
          <label className='labelXS contentSecondary'>Planned move date</label>
          <div className='labelM contentPrimary'>
            { moveDate != null ? format.date(moveDate) : '—' }
          </div>
        </div>
        <div className={CSS.editButton} onClick={editDate}>
          <Icon symbol='Edit' library='general' size='20px' />
        </div>
      </div>

    </div>
  );
};
