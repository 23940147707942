import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Button, Icon, usePopover } from '@moved/ui';

import CSS from './DateSelectPopover.module.scss';

const convertToDate = (date) => (date ? new Date(date + 'T00:00') : null);

export const DateSelectPopover = ({
  title,
  initialDate,
  onSelect,
}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const popover = usePopover();

  const updateDate = (date) => {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
    setSelectedDate(formattedDate);
  };

  const selectDate = (date) => {
    onSelect(date);
    popover.hide();
    popover?.parent?.hide(); // if this popover is nested in another popover, hide that one too
  };

  return (
    <div className='stackVertical'>
      <div className='stackHorizontal items-center justify-between'>
        <h3 className='contentPrimary'>{title}</h3>
        <span className={CSS.close_icon} onClick={popover.hide}>
          <Icon symbol='Close' library='navigation'/>
        </span>
      </div>

      <DatePicker
        selected={convertToDate(selectedDate)}
        onChange={updateDate}
        minDate={moment().toDate()}
        inline
        calendarClassName={CSS.calendar}
      />

      <div className='stackHorizontal items-center justify-between'>
        { initialDate ? (
          <span
            className={CSS.clear_button}
            onClick={() => updateDate() ?? selectDate('')}
          >
            Clear
          </span>
        ) : (
          <span></span>
        )}
        <Button
          text='Save'
          onClick={() => selectDate(selectedDate)}
          color='primary'
          disabled={!selectedDate}
        />
      </div>
    </div>
  );
};
