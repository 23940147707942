import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import CSS from './InputButton.module.scss';

export const InputButton = ({
  text,
  icon,
  onClick,
  onDelete,
  className,
}) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <div
      className={classNames(CSS.inputButtonContainer, className)}
      onClick={onClick}
    >
      <button
        className={CSS.inputButton}
        type='button'
      >
        <Icon
          className={CSS.buttonIcon}
          library={icon.library}
          symbol={icon.symbol}
          size='20px'
        />
        <span className='labelM contentPrimary'>{text}</span>
      </button>
      { onDelete && (
        <Icon
          library='navigation'
          symbol='Close'
          onClick={handleDelete}
          className={CSS.deleteIcon}
          size='20px'
        />
      )}
    </div>
  );
};
