import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_COI_SUBMISSION',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/coi-submissions`, data).then(r => r.data.data.coi_submission),
  reducers: {
    success: (state, { response: coiSubmission }) => {
      return {
        ...state,
        coiSubmissions: {
          ...state.coiSubmissions,
          [coiSubmission.id]: coiSubmission,
        },
      };
    }
  }
});
