import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_REGISTER_DIRECT',
  request: (data={}) => request.post(`/residents/users`, data, { withCredentials: true }).then(r => get(r,'data.data')),
};

export const {
  action,
  reducer,
  usePending: useRegisterDirectPending,
} = generate.redux(creator);

export const initialState = {};
