import React, { useState, useEffect, useCallback } from 'react';

import { TaskFlowScreen } from './TaskFlowScreen';

import { ScreenLoading } from '../types/shared';
import { TaskScreenTransition } from './TaskScreenTransition';
import { Train } from './Train';

// Main Taskflow Component
export const PersonalTaskFlow = ({
  taskDetails,
  taskDefinition,
  moveId,
  onClose,
  dashboardRoute,
}) => {
  const [activeScreenIndex, setActiveScreenIndex] = useState();

  const activeScreen = activeScreenIndex != null && taskDefinition.flatFlow[activeScreenIndex];
  const getRecommendedScreenIndex = useCallback((data) => {
    const recommendedScreen = taskDefinition.getRecommendedScreen(data);
    return taskDefinition.flatFlow.findIndex(screen => screen === recommendedScreen);
  },[taskDefinition]);

  // Initial screen selection
  useEffect(() => {
    // if no task details loaded, we can't determine the screen
    if(!taskDetails || activeScreenIndex != null) return;
    setActiveScreenIndex(getRecommendedScreenIndex(taskDetails));
  },[taskDetails, taskDefinition, activeScreenIndex, getRecommendedScreenIndex]);

  // Go to a specific screen
  const goToScreenIndex = (index, data) => {
    if(data) taskDefinition.updateFlow(data);
    const requestedScreen = taskDefinition.flatFlow[index];
    if(!requestedScreen) return goToScreenIndex(getRecommendedScreenIndex(data ?? taskDetails));
    if(!taskDefinition.canAccessScreen(data ?? taskDetails,requestedScreen.slug)) return false;
    setActiveScreenIndex(index);
    return true;
  };

  const goToScreen = (screen) => {
    if(!screen || !taskDefinition.canAccessScreen(taskDetails,screen.slug,screen.context)) return;
    const requestedScreenIndex = taskDefinition.flatFlow.findIndex(
      ({ slug, context }) => screen.slug === slug && (context == null || screen.context === context)
    );
    goToScreenIndex(requestedScreenIndex);
  };
  // Return to previous screen
  const previousScreen = (data) => goToScreenIndex(activeScreenIndex - 1, data);
  // Advance to next screen
  const nextScreen = (data) => goToScreenIndex(activeScreenIndex + 1, data);

 if(!activeScreen) return null;

  return (
    <TaskFlowScreen origin={dashboardRoute}>

      <TaskFlowScreen.Sidebar taskDefinition={taskDefinition} origin={dashboardRoute}>
        <Train
          taskDetails={taskDetails}
          taskDefinition={taskDefinition}
          activeScreen={activeScreen}
          previousScreen={previousScreen}
          goToScreen={goToScreen}
          onClose={onClose}
        />
      </TaskFlowScreen.Sidebar>

      <TaskFlowScreen.Content>
        { activeScreen ? (
          <TaskScreenTransition
            /* needed for the screen transition component */
            screen={activeScreen}
            index={activeScreenIndex}
            /* needed for all screens */
            task={taskDefinition}
            taskDetails={taskDetails}
            taskDefinition={taskDefinition}
            origin={dashboardRoute}
            goToScreen={goToScreen}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            onClose={onClose}
            /* needed for personal task flow screens */
            moveId={moveId}
            /* needed ONLY for the building move task flow screens (not relevant for BookMovers) */
            // taskSummary={activeTaskSummary}
            // taskBaseRoute={activeTaskBaseRoute}
          />
        ) : (
          <ScreenLoading />
        )}
      </TaskFlowScreen.Content>

    </TaskFlowScreen>
  );
};
