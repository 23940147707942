import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_UPDATE_TASK_LIST',
  request: (id, data={}) => request.patch(`/residents/personal-task-lists/${id}`, data)
    .then(r => r?.data?.data?.personal_task_list),
  reducers: {
    success: (state, { response: taskList }) => ({
      ...state,
      taskLists: {
        ...state.taskLists,
        [taskList.id]: taskList,
      },
    }),
  },
};

export const {
  action: updateTaskList,
  reducer,
  usePending: useUpdateTaskListPending,
} = generate.redux(creator);

export const initialState = {
  taskLists: {},
};
