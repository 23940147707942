import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_BOOK_MOVERS_RETRY_PREAUTH',
  request: (id, data={}) => request.post(`/residents/service-orders/${id}/customer-preauthorization-retries`, data).then(r => r?.data?.data?.booked_service_order),
  reducers: {
    success: (state, { response: serviceOrder }) => {
      // must find the parent moverBooking because we don't store service_orders as first class entities
      const moverBookingId = Object.keys(state.moverBookings).find(id => state?.moverBookings?.[id]?.booked_service_order?.id === serviceOrder.id);
      const moverBooking = state.moverBookings[moverBookingId];
      // update the mover_booking that has this service_order booked
      return {
        ...state,
        moverBookings: {
          ...state.moverBookings,
          [moverBookingId]: {
            ...moverBooking,
            booked_service_order: serviceOrder,
          },
        },
      };
    }
  }
}

export const {
  action,
  reducer,
  usePending: useRetryPreauthPending,
} = generate.redux(creator);
