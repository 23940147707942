import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_PETSCREENING_PROFILE_CLICK_TRACK',
  request: (id) => request.post(`/residents/petscreening-profiles/${id}/link-clicks`,{}).then(r => r?.data?.data?.petscreening_profile?.cta_url),
};

export const {
  action,
  reducer,
  usePending: useClickPetscreeningProfilePending,
} = generate.redux(creator);
