import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useUser, useNotify, userService, useModal } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';
import { LayoutBuildingPromo } from '@moved/product';

import { getMove } from '../../moves/actions';
import { OnboardingWizard } from '../../dashboard';
import { getDashboardRoute } from '../../dashboard/helpers';
import { useBuilding } from '../../common/actions/selectors';
import { ClaimAccountForm } from './ClaimAccountForm';
import { ClaimAccountSummary } from './ClaimAccountSummary';
import { ClaimAccountInvalid } from './ClaimAccountInvalid';
import { getClaimCode } from '../actions';

import CSS from '../styles/ClaimAccount.module.scss';

export const ClaimAccount = () => {
  const { code, buildingSlug } = useParams();
  const dispatch = useDispatch();
  const modal = useModal();
  const history = useHistory();
  const { user, isAuthenticated, logout } = useUser();
  const notify = useNotify();
  const building = useBuilding(buildingSlug);
  const [claim, setClaim] = useState(false);
  const [loading, setLoading] = useState(true);

  const title = claim.move_step_type === 'move-in' ? 'Welcome!' : 'Sorry to see you go!';
  const subtitle = claim.move_step_type === 'move-in' ? `Let's get you moved in.` : 'We have a few final tasks for you to take care of.';

  useEffect(() => {
    dispatch(getClaimCode(code))
      .then((claimData) => {
        if(claimData.building_slug !== buildingSlug) history.replace(`/signup/${claimData.building_slug}/${code}`);
        setClaim(claimData);
        setLoading(false);
      })
      .catch((error) => {
        // special handler to redirect to signin screen if this token was already claimed
        if((error?.response?.data?.errors ?? []).find(error => error.code === 'tenant-event-already-claimed')) {
          return history.replace(`/signin/${buildingSlug}`);
        }
        notify.error('This link is no longer valid.');
        setLoading(false);
      });
  },[code]); // eslint-disable-line

  const onComplete = async ({ move_id, move_step_id, login_response }) => {
    const move = await dispatch(getMove(move_id));
    // Handle onboarding wizard
    const useOnboardingWizard = move?.move_steps?.length < 2 && move?.dashboard_wizard_enabled;
    if(useOnboardingWizard) modal.open(<OnboardingWizard move={move} />);
    else notify.default(userService.getDomainBasedGreeting(login_response?.user));
    // Handle dashboard redirect
    setTimeout(() => {
      if(move_id && move_step_id) history.push(getDashboardRoute(move_id, move_step_id));
      else userService.redirect(login_response?.user,history);
    }, useOnboardingWizard ? 1000 : 0); // give the transition a delay to allow the onboarding wizard to open first
  };

  if(loading || !building) return <LoaderOverlay />;

  return (
    <LayoutBuildingPromo building={building}>
      <Helmet>
        <title>Welcome to { building ? `${(building?.settings?.display_name ?? '')} : ` : '' }Moved</title>
      </Helmet>

      { isAuthenticated && (
        <div className={CSS.view_toggle}>
          <span className="mr-10">{ user.fullname }</span>
          <span className="faux-link" onClick={() => logout()}>
            Sign out
          </span>
        </div>
      )}

      <div className={CSS.form_wrapper}>
        { (!loading && !claim) ? (
          <ClaimAccountInvalid
            title='Oops'
            subtitle='This link is not valid. Please double check the url, or reach out to your building for a new invitation link.'
            building={building}
          />
        ) : isAuthenticated ? (
          <ClaimAccountSummary
            title={title}
            subtitle={subtitle}
            code={code}
            claim={claim}
            onComplete={onComplete}
          />
        ) : (
          <ClaimAccountForm
            title={title}
            subtitle={subtitle}
            code={code}
            claim={claim}
            onComplete={onComplete}
          />
        )}
      </div>

    </LayoutBuildingPromo>
  );
};
