import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useMobile } from '@moved/services';
import { Icon } from '@moved/ui';

import desktopCSS from '../../../tasks/components/styles/TrainDesktop.module.scss';
import mobileCSS from '../../../tasks/components/styles/TrainMobile.module.scss';

// Main Taskflow Component
export const PurchaseRentersInsuranceFlowTrain = ({ purchasedPolicy, origin }) => {
  const isMobile = useMobile();

  const percentageComplete = ((purchasedPolicy == null ? 1 : 2)/2)*100;
  const activeProgressBarHeight = ((purchasedPolicy == null ? 1 : 2 ) * 76) - 16;
  return isMobile ? (
    <div className={mobileCSS.train}>
      <div className={mobileCSS.station}>
        <div
          className={classNames(mobileCSS.rail, { [mobileCSS.complete]: (percentageComplete>=100) })}
          style={{ width: `${percentageComplete}%`, }}
        />
      </div>
      <Link to={origin} className={mobileCSS.nav}>
        <Icon symbol='Close' library='navigation' size='24px' />
      </Link>
    </div>
  ) : (
    <div className={classNames('stackVertical justify-center items-center',desktopCSS.train)}>
      <div className={desktopCSS.track}>

        <div className={classNames(desktopCSS.stop, purchasedPolicy == null ? desktopCSS.current : desktopCSS.completed)}>
          <span className={desktopCSS.number}>1</span>
          <span className={desktopCSS.text}>Get renters insurance</span>
        </div>
        <div className={classNames(desktopCSS.stop,  purchasedPolicy != null && [desktopCSS.completed, desktopCSS.current])}>
          <span className={desktopCSS.number}>2</span>
          <span className={desktopCSS.text}>Summary</span>
        </div>

        <div className={desktopCSS.lines} style={{ height: activeProgressBarHeight }}>
          <span className={classNames(desktopCSS.circle, desktopCSS.active_circle)} />
          <span className={classNames(desktopCSS.circle, { [desktopCSS.active_circle]: purchasedPolicy != null })} />
        </div>

      </div>
    </div>
  );
};
