import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';
import { useHoverDirty } from 'react-use';
import classNames from 'classnames';

import { useUser, format, useModal } from '@moved/services';
import { Button, Icon, PopoverMenu, Tooltip, Badge, ProgressRing } from '@moved/ui';

import { useMove } from '../../moves/actions/selectors';
import { ExploreTasksModal } from '../../personalTasks/modals/ExploreTasksModal';

import { ReactComponent as MovedIcon } from './images/sidebarMovedIcon.svg';
import { ReactComponent as MovedLogo } from './images/sidebarMovedLogo.svg';

import CSS from './styles/Sidebar.module.scss';

const SIDEBAR_LOCK_EXPANDED = 'dashboardSidebarLockExpanded';
const initialExpanded = JSON.parse(localStorage.getItem(SIDEBAR_LOCK_EXPANDED)) ?? (
  process.env.REACT_APP_ENV_NAME !== 'staging' // default to collapsed on staging only (joe)
);

export const Sidebar = ({ expanded }) => {
  const { moveId } = useParams();
  const { user, logout } = useUser();
  const modal = useModal();
  const move = useMove(moveId);

  const sidebarElement = useRef(null);
  const isHovered = useHoverDirty(sidebarElement);
  const [lockExpanded, setLockExpanded] = useState(initialExpanded);

  const moveSteps = move?.move_steps ?? [];
  // convenience value for feature flag presense for marketplace
  const isMarketplaceEnabled = moveSteps?.length === 0 || moveSteps?.some(step => step?.building?.settings?.marketplace_enabled);

  // persist the lockExpanded state to local storage
  useEffect(() => {
    localStorage.setItem(SIDEBAR_LOCK_EXPANDED,JSON.stringify(lockExpanded));
  },[lockExpanded])

  // There are three separate mechanisms for expanding the sidebar:
  // 1. The sidebar is expanded when the user hovers over it
  // 2. The sidebar is expanded when the user clicks on the lock expanded icon
  // 3. The sidebar is expanded when the expanded prop is true (used for mobile view)
  const isExpanded = lockExpanded || expanded || isHovered;

  const exploreTasks = () => modal.open(
    <ExploreTasksModal taskListId={move?.personal_task_list_id} moveId={move.id} />
  );

  const getCompletedRequiredTasks = moveStep => (moveStep?.move_tasks ?? [])
    .filter(task => task.requirement === 'required' && task.status === 'complete');
  const getRequiredTasks = moveStep => (moveStep?.move_tasks ?? [])
    .filter(task => task.requirement === 'required');

  return (
    <div className={classNames(CSS.wrapper,{
      [CSS.expanded]: isExpanded, // used to signal all style changes when expanded
      [CSS.locked]: lockExpanded, // used to ensure the body content is displaced when the sidebar is locked
    })}>

      <div className={
        classNames(
          'stackVertical justify-between height-full',
          CSS.sidebar,
        )
      } ref={sidebarElement}>

        <div className={classNames('stackVertical items-start', CSS.topSection)}>

          <div className={classNames('hidden--mobile width-full', CSS.logoBlock)}>
            { isExpanded ? (
              <div className='stackHorizontal justify-between align-center'>
                <MovedLogo className={CSS.logo} />
                <Tooltip tooltip={lockExpanded ? 'Unpin' : 'Pin'} placement='left'>
                  <Icon
                    library='navigation'
                    symbol='Arrow-to-right'
                    onClick={() => setLockExpanded(!lockExpanded)}
                    className={CSS.lockIcon}
                    color='inherit'
                  />
                </Tooltip>
              </div>
            ) : (
              <MovedIcon className={CSS.logo} />
            )}
          </div>

          <div className='stackVertical gap-16 width-full paddingTop-40 paddingTop-24--mobile'>
            <div className='stackVertical gap-8'>
              { move?.move_steps?.map(moveStep => (
                <NavLink
                  to={`/moves/${moveId}/steps/${moveStep.id}`}
                  className={classNames('stackHorizontal justify-between', CSS.navItem)}
                  activeClassName={CSS.active}
                  key={moveStep.id}
                >
                  <div className='stackHorizontal gap-12 width-full items-center'>
                    { moveStep.type === 'move-in' ? (
                      <Icon library='home' symbol='Home-heart' className={CSS.twoColor} color='inherit' />
                    ) : (
                      <Icon library='home' symbol='Home' color='inherit' />
                    )}
                    <div className={classNames('labelL flex-auto',CSS.label)}>{ moveStep.display_name }</div>
                    <Tooltip
                      tooltip={`${getCompletedRequiredTasks(moveStep).length} of ${getRequiredTasks(moveStep).length} tasks completed`}
                      className={CSS.hiddenCollapsed}
                      mountToBody={true}
                    >
                      <ProgressRing
                        progress={getCompletedRequiredTasks(moveStep).length}
                        total={getRequiredTasks(moveStep).length}
                        showFraction={false}
                        size={20}
                      />
                    </Tooltip>
                  </div>
                </NavLink>
              ))}
              <NavLink
                to={`/moves/${moveId}/personal-tasks`}
                className={classNames('stackHorizontal justify-between', CSS.navItem)}
                activeClassName={CSS.active}
              >
                <div className='stackHorizontal gap-12'>
                  <Icon library='general' symbol='Ai' color='inherit' />
                  <div className={classNames('labelL',CSS.label)}>Personal</div>
                  { move?.personal_task_list_is_new_count > 0 && (
                    <Badge
                      size='small'
                      type='error'
                      dark={true}
                      text={String(move?.personal_task_list_is_new_count)}
                      className='paddingLeft-12 paddingRight-12'
                    />
                  )}
                </div>
              </NavLink>
              { isMarketplaceEnabled && (
                <NavLink
                  to={`/moves/${moveId}/marketplace`}
                  className={classNames('stackHorizontal justify-between', CSS.navItem)}
                  activeClassName={CSS.active}
                >
                  <div className='stackHorizontal gap-12'>
                    <Icon library='shopping' symbol='Price#1' color='inherit' />
                    <div className={classNames('labelL',CSS.label)}>Moving Offers</div>
                  </div>
                </NavLink>
              )}
            </div>
            { move?.personal_task_list_id && (
              // Can not show the explore modal unless a personal task list exists
              <div className={CSS.exploreSection}>
                <Button
                  text='Add personal tasks'
                  color='tertiary'
                  icon={{library:'navigation',symbol:'Plus'}}
                  onClick={exploreTasks}
                  className={classNames(CSS.exploreButton,CSS.hiddenCollapsed)}
                />
              </div>
            )}
          </div>

        </div>

        <div className={classNames(CSS.profileSection)}>
          <PopoverMenu position='top-end'>
            <PopoverMenu.Trigger
              onClick={e => e.stopPropagation()}
              className={classNames(CSS.profileMenu)}
              activeClassName={CSS.open}
            >
              <div className='stackHorizontal gap-12 justify-between items-center'>
                <div className='stackHorizontal gap-12 items-center flexTruncateFix'>
                  <div className={CSS.oval}>
                    <span className='headingS contentInversePrimary'>{format.initials(user)}</span>
                  </div>
                  <div className={classNames('stackVertical',CSS.hiddenCollapsed)}>
                    <div className='labelL contentPrimary textTruncate'>{ format.fullname(user) }</div>
                    <div className='labelS contentSecondary textTruncate'>{ user.email }</div>
                  </div>
                </div>
                <Icon library='navigation' symbol='Chevron-down' color='inherit' className={CSS.menuIcon} />
              </div>
            </PopoverMenu.Trigger>
            <PopoverMenu.Content large className={CSS.hiddenCollapsed}>
              <Link to='/profile'>
                <PopoverMenu.Item
                  label={'Profile'}
                  icon={{ library: 'general', symbol: 'User' }}
                />
              </Link>
              <Link to='/moves'>
                <PopoverMenu.Item
                  label={'My Moves'}
                  icon={{ library: 'map', symbol: 'Marker#1' }}
                />
              </Link>
              <PopoverMenu.Item
                label={'Sign out'}
                icon={{ library: 'navigation', symbol: 'Sign-out' }}
                onClick={logout}
              />
            </PopoverMenu.Content>
          </PopoverMenu>
        </div>
      </div>

    </div>
  )
}
