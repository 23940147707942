import { useDispatch } from 'react-redux';
import moment from 'moment';

import { format, useNotify } from '@moved/services';
import { Popover } from '@moved/ui';

import { DateSelectPopover } from './DateSelectPopover';
import { InputButton } from './InputButton';
import { updateTask } from '../../actions/updateTask';
import { getTaskList } from '../../actions/getTaskList';

export const DueDateInput = ({ taskDetails, taskListId, label, className }) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const currentDueDate = moment(taskDetails?.due_date ?? 'none').isValid()
    ? moment(taskDetails.due_date).format('YYYY-MM-DD')
    : '';

  const updateDueDate = (newDate) => {
    const formattedDueDate = newDate ? moment(newDate).format('YYYY-MM-DD') : '';
    return dispatch(updateTask(taskDetails.id, { due_date: formattedDueDate }))
      .then(() => dispatch(getTaskList(taskListId))) // refresh the task list
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Popover>
      <Popover.Trigger className='height-full'>
        <InputButton
          text={currentDueDate ? moment(currentDueDate).format('MMM Do') : label}
          icon={{ library: 'code', symbol: 'Date' }}
          onDelete={currentDueDate ? () => updateDueDate('') : null}   // Only show X to delete if due date present
          className={className}
        />
      </Popover.Trigger>
      <Popover.Content>
        <DateSelectPopover
          title='Add due date'
          initialDate={currentDueDate}
          onSelect={updateDueDate}
        />
      </Popover.Content>
    </Popover>
  );
};
