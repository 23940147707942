import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_HAPPYCO_INSPECTION_CLICK_TRACK',
  request: (id) => request.post(`/residents/happyco-inspections/${id}/link-clicks`,{}).then(r => get(r,'data.data.happyco_inspection.url')),
};

export const {
  action,
  reducer,
  usePending: useClickHappycoInspectionPending,
} = generate.redux(creator);
