import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Icon, Card, Button, Modal, Form, FormAddress } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { updateMove } from '../../moves/actions';
import { useUpdateMovePending } from '../../moves/actions/selectors';

import CSS from './EditModals.module.scss';

export const EditAddressModal = ({ name, initial, move }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const modal = useModal();

  const pending = useUpdateMovePending();

  const submit = (data) => {
    dispatch(updateMove(move?.id, data))
      .then(() => modal.close())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Modal className={CSS.container}>
      <Modal.Title>Edit {name} address</Modal.Title>
      <Modal.Content>
        <Form
          name={'address-form'}
          onSubmit={submit}
          validation={Yup.object({
            [`${name}_address`]: Yup.object().required('Required'),
          })}
        >
          <div className='stackHorizontal gap-24 items-center'>
            <Card className='padding-8 radius-full'>
              <Icon
                library='map'
                symbol={format.capitalize(name)}
              />
            </Card>
            <FormAddress
              name={`${name}_address`}
              label={format.capitalize(name)}
              value={initial}
              className='width-full'
            />
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={modal.close}
        />
        <Button
          text='Save'
          type='submit'
          form='address-form'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
};
