import {
  AccountDetails,
  GridRewardsScreen,
  PublicGridScreen,
  SelectProvider,
  UtilitySetupSummary,
} from './components'

export const screens = {

  'select-provider': {
    slug: 'provider',
    label: 'Set up account',
    title: 'Set up your utility provider',
    subtitle: 'Be sure to set up your account prior to your move to ensure continuity of service.',
    component: SelectProvider,
    isCompleted: (data, { helpers }) => helpers.getActiveRequest(data)?.utility_provider?.id,
    maxWidth: 710,
  },
  'account-details': {
    slug: 'details',
    label: 'Account details',
    title: 'Provide proof of account setup',
    subtitle: (<>We require a utility account set up in a leaseholder&rsquo;s name prior to move-in.</>),
    component: AccountDetails,
    isCompleted: (data, { helpers }) => {
      const {
        account_number_required,
        confirmation_number_required,
        upload_required,
        gridrewards_enabled,
      } = data.settings;
      const activeRequest = helpers.getActiveRequest(data);
      if(gridrewards_enabled) {
        return (!account_number_required || activeRequest?.account_number)
          && (!confirmation_number_required || activeRequest?.confirmation_number)
          && (!upload_required || activeRequest?.file_url);
      } else {
        return (activeRequest?.status ?? 'draft') !== 'draft';
      }
    },
    maxWidth: 592,
  },

  'gridrewards': {
    slug: 'gridrewards',
    label: 'Rewards',
    title: 'Sign up for GridRewards',
    component: GridRewardsScreen,
    isCompleted: (data, { helpers }) => (helpers.getActiveRequest(data)?.status ?? 'draft') !== 'draft',
    maxWidth: 'none',
  },

  'public-grid': {
    slug: 'public-grid',
    label: 'Public Grid',
    component: PublicGridScreen,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return (
        activeRequest?.public_grid_opt_in != null ||
        (activeRequest?.status ?? 'draft') !== 'draft'
      );
    },
    maxWidth: 'none',
  },

  'summary': {
    slug: 'summary',
    component: UtilitySetupSummary,
    label: "Summary",
    backRestricted: true,
    isCompleted: (data, { helpers }) => helpers.getActiveRequest(data)?.status === 'approved',
  },
};
