import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CONSUMER_TASKS_GET_S3_UPLOAD_URL',
  request: (buildingId, data={}) => request.post(`/residents/buildings/${buildingId}/presigned-s3-urls`, data)
    .then(r => get(r,'data.data.presigned_s3_url')),
};

export const {
  action,
  reducer,
} = generate.redux(creator);
