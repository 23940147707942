import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_UTILITIES',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/utilities`, data).then(r => r.data.data),
  reducers: {
    success: (state, { response: utility }) => {
      return {
        ...state,
        utilities: {
          ...state.utilities,
          [utility.id]: utility,
        },
      };
    }
  }
});
