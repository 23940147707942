import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_UTILITY_SETUP',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/utility-setup-tasks`, data).then(r => r.data.data.utility_setup_task),
  reducers: {
    success: (state, { response: utilitySetup }) => {
      return {
        ...state,
        utilitySetups: {
          ...state.utilitySetups,
          [utilitySetup.id]: utilitySetup,
        },
      };
    }
  }
});
