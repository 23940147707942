import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_UPDATE_MOVE_STEP',
  request: (id,data={}) => request.patch(`/residents/move-steps/${id}`,data).then(r => r?.data?.data?.move_step),
  reducers: {
    success: (state, { response: move_step, params: [id] }) => {
      const activeMove = Object.values(state.moves)?.find(move => move.move_steps.find(step => step.id === id));
      return {
        ...state,
        moves: {
          ...state.moves,
          [activeMove.id]: {
            ...activeMove,
            move_steps: activeMove.move_steps.map(step => step.id === id ? move_step : step),
          },
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateMoveStepPending,
} = generate.redux(creator);

export const initialState = {
  moves: {},
};
