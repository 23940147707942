import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_CLAIM_ACCOUNT',
  request: (buildingId, data={}) => request.post(`/residents/buildings/${buildingId}/dashboards`, data, { withCredentials: true }).then(r => get(r,'data.data.dashboard')),
};

export const {
  action,
  reducer,
  usePending: useClaimAccountPending,
} = generate.redux(creator);

export const initialState = {};
