import React from 'react';

import { CompleteCard, FieldList } from '@moved/ui';
import { format } from '@moved/services';

import {
  ScreenLayout,
  ScreenContent,
} from '../../../tasks/types/shared';

export const CompleteInsuranceScreen = ({ screen, purchasedPolicy }) => {

  const attributes = [
    {
      label: 'Provider',
      value: purchasedPolicy?.insurance_provider,
      icon: { symbol: 'Home', library: 'home' },
    },
    {
      label: 'Policy number',
      value: purchasedPolicy?.policy_number,
      icon: { symbol: 'File', library: 'files' },
    },
    {
      label: 'Coverage start date',
      value: format.date(purchasedPolicy?.coverage_start),
      icon: { symbol: 'Date', library: 'code' },
    },
    {
      label: 'Coverage end date',
      value: format.date(purchasedPolicy?.coverage_end),
      icon: { symbol: 'Date-to', library: 'code' },
    },
    {
      label: 'Liability coverage amount',
      value: format.currency(purchasedPolicy?.liability_coverage_amount * 100),
      icon: { symbol: 'Shield-check', library: 'general' },
    },
    purchasedPolicy?.files?.length > 0 && {
      label: 'Policy documents',
      value: (
        <div className='stackVertical gap-4'>
          { purchasedPolicy?.files?.map(file => (
            <a href={file.url} target='_blank' rel='noopener noreferrer' key={file.document_name}>
              { file.document_name }
            </a>
          ))}
        </div>
      ),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
  ].filter(Boolean);

  return (
    <ScreenLayout>
      <ScreenContent screen={screen}>
        <CompleteCard
          status='approved'
          title={`You're covered!`}
          flavor='The details of your new policy are below.'
        >
          <FieldList list={attributes} />
        </CompleteCard>
      </ScreenContent>
    </ScreenLayout>
  );
};
