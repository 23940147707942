import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { DateTime } from 'luxon';

import { Icon, Card, Button, Modal, Form, FormDateSelect } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { updateMove } from '../../moves/actions';
import { useUpdateMovePending } from '../../moves/actions/selectors';

import { getMoveProgress } from '../actions/getMoveProgress';

import CSS from './EditModals.module.scss';

export const EditDateModal = ({ initial, move }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const modal = useModal();

  const pending = useUpdateMovePending();

  const submit = (data) => {
    dispatch(updateMove(move?.id, data))
      .then(() => dispatch(getMoveProgress(move?.personal_task_list_id)))
      .then(() => modal.close())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Modal className={CSS.container}>
      <Modal.Title>Edit planned move date</Modal.Title>
      <Modal.Content>
        <Form
          name='date-form'
          onSubmit={submit}
          validation={Yup.object({
            move_date: Yup.string().nullable().required('Required'),
          })}
        >
          <div className='stackHorizontal gap-24 items-center'>
            <Card className='padding-8 radius-full'>
              <Icon library='code' symbol='Date' />
            </Card>
            <FormDateSelect
              name='move_date'
              label='Move date'
              value={initial}
              className='width-full'
              minDate={DateTime.now().toISODate()}
            />
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={modal.close}
        />
        <Button
          text='Save'
          form='date-form'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
};

export const Content = ({ className, children }) => (
  <div className={classNames('stackVertical gap-28',CSS.content,className)}>{ children }</div>
);

export const SideImage = ({ src }) => (
  <div className={CSS.sideImage}>
    <img src={src} alt='Step Preview' className='radiusLeft-16' />
  </div>
);

export const Actions = ({ className, children }) => (
  <div className={classNames(CSS.actions,className)}>{ children }</div>
);
