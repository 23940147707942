import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'DASHBOARD_GET_MARKETPLACE_AD_REDIRECT',
  request: (data={}) => request.post(`/residents/marketplace-ad-redirects`,data).then(r => get(r,'data.data.marketplace_ad_redirect')),
}

export const {
  action,
  reducer,
  usePending: useMarketplaceAdRedirectPending,
} = generate.redux(creator);

export const initialState = {};
