import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as submit from './submit';

export const reducers = [
  create.reducer,
  get.reducer,
  submit.reducer,
];

export const initialState = merge(
  create.initialState,
  get.initialState,
  submit.initialState,
);
