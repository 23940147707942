import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useModal } from '@moved/services';
import {
  Button,
  Modal,
} from '@moved/ui';

import { ResidentContentRenderer } from '../../common';

import { updateTaskList } from '../actions/updateTaskList';

import introContent from './introModalContent.json';

export const IntroModal = ({ taskListId }) => {
  const modal = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    // update the task list to set the latest progress
    dispatch(updateTaskList(taskListId, { video: 1 }));
  },[taskListId, dispatch]);

  return (
    <Modal style={{ maxWidth:'650px' }}>
      <Modal.Title>Welcome to your personalized dashboard</Modal.Title>
      <Modal.Content>
        <ResidentContentRenderer content={introContent} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Ok'
          onClick={modal.close}
        />
      </Modal.Actions>
    </Modal>
  );
}
