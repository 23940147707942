import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_INSURANCE_GET_PROVIDER_LINK',
  request: (id,providerId) => request.post(`/renters-insurance-tasks/${id}/third-party-providers/${providerId}/link`,{}).then(r => get(r, 'data.data.renters_insurance_link.url')),
};

export const {
  action,
  reducer,
  usePending: useProviderLinkPending,
} = generate.redux(creator);

export const initialState = {};
