import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_ORIENTATION',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/orientation-progress`, data).then(r => r.data.data.user_orientation_progress ),
  reducers: {
    success: (state, { response: orientationContent }) => {
      return {
        ...state,
        orientation: {
          ...state.orientation,
          [orientationContent.id]: orientationContent,
        },
      };
    }
  }
});
