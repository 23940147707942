import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_INSURANCE',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/renters-insurance-tasks`, data).then(r => r.data.data.renters_insurance_task),
  reducers: {
    success: (state, { response: insuranceTask }) => {
      return {
        ...state,
        renterInsurances: {
          ...state.renterInsurances,
          [insuranceTask.id]: insuranceTask,
        },
      };
    }
  }
}

export const {
  action,
  reducer,
  useResourcePending: useCreateRenterInsurancePending,
} = generate.redux(creator);

export const initialState = {
  renterInsurances: {},
}
