import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_PETSCREENING_VERIFICATION_TASK',
  request: (taskID) => request.post(`/move-tasks/${taskID}/petscreening-verification-tasks`,{}).then(r => r?.data?.data?.petscreening_verification_task),
  reducers: {
    success: (state, { response: petscreeningVerificationTask }) => ({
      ...state,
      petscreeningVerificationTasks: {
        ...state.petscreeningVerificationTasks,
        [petscreeningVerificationTask.id]: petscreeningVerificationTask,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useCreatePetscreeningVerificationTaskPending,
} = generate.redux(creator);
