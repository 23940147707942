import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import { Drawer } from '@moved/ui';

import { useMove } from '../../../moves/actions/getMove';
import { useTask } from '../../actions/getTask';
import { getInsurancePurchaseIntent, useInsurancePurchaseIntent } from './actions/get';
import { createInsurancePurchaseIntent } from './actions/create';
import { PurchaseRentersInsuranceFlow } from './PurchaseRentersInsuranceFlow';

export const PurchaseRentersInsuranceDrawer = ({ taskId, moveId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const drawer = useDrawer();
  const personalTask = useTask(taskId);
  const move = useMove(moveId);

  const purchaseIntent = useInsurancePurchaseIntent(personalTask?.task_details_id);

  useEffect(() => {
    if(!personalTask || purchaseIntent) return;
    dispatch(
      personalTask?.task_details_id ?
        getInsurancePurchaseIntent(personalTask.task_details_id) :
        createInsurancePurchaseIntent(personalTask.id)
    )
      .catch(err => {
        notify.error(format.error(err));
        drawer.close();
      });
  }, []); // eslint-disable-line

  return (
    <Drawer>
      <PurchaseRentersInsuranceFlow
        personalTask={personalTask}
        purchaseIntent={purchaseIntent}
        move={move}
        dashboardRoute={`/moves/${moveId}/personal-tasks`}
      />
    </Drawer>
  );
}
