import { useDispatch } from 'react-redux';
import moment from 'moment';

import { format, useModal, useNotify } from '@moved/services';

import { InputButton } from './InputButton';
import { ReminderSelectModal } from './ReminderSelectModal';
import { getTask } from '../../actions/getTask';
import { getTaskList } from '../../actions/getTaskList';
import { deleteReminder } from '../../actions/deleteReminder';

export const ReminderInput = ({ taskDetails, taskListId, label, className }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  const taskId = taskDetails.id;
  const reminder = taskDetails.reminder;
  const reminderDatetime = taskDetails.reminder?.reminder_datetime;

  const openReminderModal = () => {
    modal.open(
      <ReminderSelectModal
        taskDetails={taskDetails}
        taskListId={taskListId}
      />
    );
  };

  const handleClearReminder = () => {
    return dispatch(deleteReminder(taskId, reminder.id))
      .then(() => dispatch(getTask(taskId)))
      .then(() => dispatch(getTaskList(taskListId)))
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <InputButton
      text={reminderDatetime ? moment(reminderDatetime).format('MMM Do') : label}
      icon={{ library: 'home', symbol: 'Alarm-clock' }}
      onClick={openReminderModal}
      onDelete={reminderDatetime ? handleClearReminder : null}
      className={className}
    />
  );
};
