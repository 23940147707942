import { generate, request } from '@moved/services';

const creator = {
  namespace: 'SURVEYS_TRACK_REVIEW_SITE_CLICK',
  request: (token, site) => request.post(`/residents/satisfaction-survey-responses/${token}/review-site-links/${site}`,{}).then(r => r.data.data.review_site_link),
};

export const {
  action,
  reducer,
} = generate.redux(creator);

export const initialState = {};
