import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_RENTERS_INSURANCE_CREATE_PURCHASE_INTENT',
  request: (id) => request.post(`/residents/personal-tasks/${id}/insurance-purchase-intents`,{}).then(r => r.data.data.insurance_purchase_intent),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      personalTasks: {
        ...state.personalTasks,
        [id]: {
          ...state.personalTasks[id],
          task_details_id: response.id,
        },
      },
      insurancePurchaseIntents: {
        ...state.insurancePurchaseIntents,
        [response.id]: response,
      }
    }),
  }
};

export const {
  action: createInsurancePurchaseIntent,
  reducer,
  usePending: useCreateInsurancePurchaseIntentPending,
} = generate.redux(creator);

export const initialState = {
  insurancePurchaseIntents: {},
};
