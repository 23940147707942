import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_MOVER_BOOKING_TASK',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/mover-booking-tasks`, data).then(r => r?.data?.data?.mover_booking_task),
  reducers: {
    success: (state, { response }) => {
      const { mover_booking, ...bookingTask } = response;
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: {
            ...bookingTask,
            mover_booking_id: mover_booking?.id,
          },
        },
        moverBookings: {
          ...state.moverBookings,
          ...(mover_booking ? { [mover_booking?.id]: mover_booking } : {}),
        }
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreateMoverBookingTaskPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingTasks: {},
};
