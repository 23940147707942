import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_PAY_MOVE_IN_COSTS_TASK',
  request: (taskID) => request.post(`/move-tasks/${taskID}/pay-move-in-costs-tasks`,{}).then(r => get(r,'data.data.pay_move_in_costs_task')),
  reducers: {
    success: (state, { response: payMoveInCostsTask }) => ({
      ...state,
      payMoveInCostsTasks: {
        ...state.payMoveInCostsTasks,
        [payMoveInCostsTask.id]: payMoveInCostsTask,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useCreatePayMoveInCostsTaskPending,
} = generate.redux(creator);
